import type { EditorPlatformApp } from '@wix/editor-platform-sdk-types';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import { blogAppDefId } from './external/common/constants/apps';
import type { EditorEvent } from './external/common/constants/events';
import { getAssetsUrl } from './external/common/services/editor-sdk';
import {
  handleBlogInstalled,
  handleEditorReady,
  handleGetAppManifest,
  handleMigrateAction,
  handleOnEvent,
} from './external/common/services/handlers';
import { getMetaSiteId } from './external/common/services/instance';
import maIntegrationKit from './external/common/services/ma-integration-kit';
import monitoring from './external/common/services/monitoring';
import type { EditorActions } from './types/editor-actions.type';
import type { EditorAppContext } from './types/editor-app-context.type';

export const createEditorApp = (): Required<EditorPlatformApp> => {
  let context: EditorAppContext | undefined;

  return {
    editorReady: (sdk, appToken, options) => {
      console.log('blog platform app is up');

      if (process.env.NODE_ENV === 'development') {
        console.log('options:', options);
      }

      const instance = options.initialAppData.instance;
      const instanceId = options.initialAppData.instanceId;
      const metaSiteId = getMetaSiteId(instance)!;
      const origin = options.origin.type;

      monitoring.init({
        fedopsLoggerFactory: options.essentials.fedopsLoggerFactory,
        metaSiteId,
        instanceId,
      });

      type OriginInfo = { installMembersArea?: boolean } | undefined;

      context = {
        sdk,
        appToken,
        isADI: origin === 'ADI' || origin === 'ADI_MA',
        isClassicEditor: origin === 'CLASSIC',
        isEditorX: origin === 'RESPONSIVE',
        installMembersArea: (options.origin.info as OriginInfo)
          ?.installMembersArea,
        instance,
        essentials: options.essentials,
        assetsUrl: getAssetsUrl(options),
        biData: options.biData,
        silentInstallation: options.silentInstallation ?? false,
      };

      return monitoring.toMonitored(
        'editor-ready',
        monitoring.toMonitored(
          'editor-ready-duration',
          handleEditorReady(context),
          true,
          120000,
        ),
      );
    },
    handleAction: async ({ type, payload }: EditorActions, sdk) => {
      if (!context) {
        return;
      }

      switch (type) {
        case 'appInstalled': {
          if (payload.appDefinitionId !== blogAppDefId) {
            break;
          }

          return monitoring.toMonitored(
            'handle-blog-installed',
            handleBlogInstalled(context),
          );
        }
        case 'migrate':
          return handleMigrateAction({ ...context, sdk }, payload.migrationId);
      }
    },
    onEvent: (event) => {
      if (!context) {
        return;
      }

      return handleOnEvent(event as EditorEvent, context);
    },
    getAppManifest: () => {
      if (!context) {
        return {};
      }

      return handleGetAppManifest(context);
    },
    exports: {},
  };
};

const editorApp = maIntegrationKit.withMembersArea(createEditorApp(), {
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.FOLLOWERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
  ],
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
export const onEvent = editorApp.onEvent;
export const getAppManifest = editorApp.getAppManifest;

export const exports = {};
