import {
  ComponentClientSpecMapEntry,
  ComponentRef,
} from '@wix/editor-platform-sdk-types';
import {
  FEED_PAGE_SECTIONS,
  SECTION_POST_LIST,
  getLayoutContentHeightTypeParam,
  PG_LAYOUTS_WITH_CONTENT_HEIGHT,
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS,
  getLayoutTypeById,
  type LayoutType,
} from '@wix/communities-blog-client-common';
import { EditorAppContext } from '../../../../types/editor-app-context.type';
import { blogAppDefId } from '../../constants/apps';
import { BLOG_WIDGET_ID, POST_LIST_WIDGET_ID } from '../../constants/widgets';
import { createUpdateStyleParams } from './create-update-style-params';

const layoutNames = PG_LAYOUTS_WITH_CONTENT_HEIGHT.map((layoutType) =>
  getLayoutTypeById(layoutType as LayoutType),
);
export const generateStylesReducer = (acc: string[], section: string) => {
  return [
    ...acc,
    ...layoutNames.map((layoutName) => {
      return getLayoutContentHeightTypeParam(section, layoutName);
    }),
  ];
};
const feedStyleKeys = FEED_PAGE_SECTIONS.reduce(generateStylesReducer, []);
const postListStyleKeys = [SECTION_POST_LIST].reduce(generateStylesReducer, []);

export const updateFeedContentHeightTypeStyles = createUpdateStyleParams(
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
  'number',
  feedStyleKeys,
);
export const updatePostListContentHeightTypeStyles = createUpdateStyleParams(
  PG_LAYOUT_INFO_SIZE_TYPE_OPTIONS.AUTO,
  'number',
  postListStyleKeys,
);

const changeFeedContentHeightType = async (
  { sdk, appToken }: EditorAppContext,
  blogAppComponents: ComponentClientSpecMapEntry[],
) => {
  const component = blogAppComponents.find(({ widgetId }) =>
    [BLOG_WIDGET_ID].includes(widgetId),
  );

  if (!component) {
    return;
  }

  const compRef = await sdk.document.components.getById(appToken, {
    id: component.id,
  });
  await updateFeedContentHeightTypeStyles(sdk, appToken, compRef);
};

const changePostListContentHeightType = async (
  { sdk, appToken }: EditorAppContext,
  blogAppComponents: ComponentClientSpecMapEntry[],
  newWidgetCompRef: ComponentRef,
) => {
  const component = blogAppComponents.find(
    (comp) => comp.id === newWidgetCompRef.id,
  );
  const newWidgetIsPostList = component?.widgetId === POST_LIST_WIDGET_ID;

  if (!newWidgetIsPostList) {
    return;
  }
  await updatePostListContentHeightTypeStyles(sdk, appToken, newWidgetCompRef);
};

export const setDefaultContentHeightType = async (
  context: EditorAppContext,
  newWidgetCompRef?: ComponentRef,
) => {
  const { sdk, appToken } = context;
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);

  if (!appData) {
    return;
  }

  const blogAppComponents = await sdk.tpa.app.getAllCompsByApplicationId(
    appToken,
    appData.applicationId,
  );

  if (newWidgetCompRef) {
    await changePostListContentHeightType(
      context,
      blogAppComponents,
      newWidgetCompRef,
    );
  } else {
    await changeFeedContentHeightType(context, blogAppComponents);
  }
};
